<template>
    <div>
        <b-overlay :show="loading">
            <b-card>
                <app-collapse>
                    <app-collapse-item title="Búsqueda"
                                       :is-visible="isVisible">
                        <b-row>
                            <b-col md="3">
                                <b-form-group
                                >
                                    <h5 class="font-weight-bold">
                                        Ficha
                                    </h5>
                                    <b-form-input
                                            id="ficha"
                                            v-model="num_ficha"
                                            placeholder="N° de Ficha"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="9">
                                <div class=" mt-2">
                                    <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-primary"
                                            type="submit"
                                            @click="buscar">

                                        <feather-icon
                                                icon="SearchIcon"
                                                class="mr-50"
                                        />
                                        <span class="align-middle">Buscar</span>
                                    </b-button>
                                    <div v-bind="showToast" class="d-none"></div>
                                </div>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-card>
            <b-card>
                <h4 class="card-title">Códigos de tablet</h4>
                <div v-if="showTables">
                <b-row class="pr-1 pl-1">
                    <b-col sm="12" md="6"  class="my-1 text-right">
                        <div class="d-flex align-items-center mb-1 mb-md-0">
                            <label>Mostrar</label>
                            <b-form-select
                                    @change="onChangePerPage"
                                    id="perPageSelect"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                    class="mx-50 col-md-2"
                            ></b-form-select>
                            <label>filas por página</label>
                        </div>
                    </b-col>
                    <b-col sm="4" md="6" class="my-1">
                        <div class="d-flex justify-content-end" >
                            <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="outline-primary"
                                    type="submit"
                                    class="ml-1"
                                    @click="exportar"
                                    :disabled="totalRows==0"
                            >
                                <feather-icon
                                        icon="DownloadIcon"
                                        class="mr-50"
                                />
                                <span class="align-middle">Exportar Códigos</span>

                            </b-button>
                            <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="outline-primary"
                                    type="submit"
                                    class="ml-1"
                                    @click="generarCodigos"
                                    :disabled="codeMissing"
                            >
                                <feather-icon
                                        icon="CheckSquareIcon"
                                        class="mr-50"
                                />
                                <span class="align-middle">Generar Códigos</span>

                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-col cols="12" v-if="showTable">
                    <b-table
                            striped
                            hover
                            small
                            :per-page="perPage"
                            :items="listado"
                            :fields="fields"
                            responsive
                            class="mb-0"
                            id="my-table"
                            ref="table"
                            show-empty
                    >
                        <template #cell()="data">
                            {{ data.value ? data.value : '-' }}
                        </template>
                        <template #empty="scope">
                            <div class="text-center">No existen resultados</div>
                        </template>
                    </b-table>

                </b-col>

                <b-row class="pl-1">
                    <b-col  cols="8" class="mt-3">
                        <div v-if="">
                            <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                        </div>
                    </b-col>

                    <b-col cols="4">
                        <b-pagination
                                @change="onPageChange"
                                :total-rows="totalRows"
                                :value="page"
                                :per-page="perPage"
                                align="right"
                                class="mt-3 mr-1"
                                aria-controls="my-table"
                        />
                    </b-col>

                </b-row>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
    import AppCollapse from "@core/components/app-collapse/AppCollapse";
    import {
        BButton,
        BCol,
        BFormGroup,
        BFormInput,
        BRow
    } from "bootstrap-vue";
    import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import store from "@/store";

    export default {
        name: "CodigosTablet",
        components: {
            AppCollapse,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BButton,
            AppCollapseItem,
            ToastificationContent
        },
        directives: {
            Ripple,
        },
        data() {
            return {
                isVisible: true,
                num_ficha: null,
                showTable: false,
                generarCodigo: true,
                mostrarMensaje: false,
                tempMensaje: '',
                perPage: 50,
                page: 1,
                fields: [
                    { key: 'rut_alumno', label: 'Rut Alumno ', display: true },
                    { key: 'nombre_alumno', label: 'Nombre Alumno ', display: true },
                    { key: 'apellidos_alumno', label: 'Apellidos Alumno ', display: true },
                    { key: 'num_ficha', label: 'N° Ficha', display: true },
                    { key: 'fecha_inicio', label: 'Fecha Inicio', display: true },
                    { key: 'fecha_fin', label: 'Fecha Fin', display: true },
                    { key: 'curso', label: 'Curso', display: true },
                    { key: 'codigo', label: 'Código', display: true },
                ],
                alumnos:[],
                pageOptions: [50, 100, 500, 1000],
            }
        },
        methods: {
            buscar() {
                if(this.num_ficha == null)
                {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Alerta!',
                            icon: 'BellIcon',
                            text: 'Debe escribir el numero de la ficha de desea buscar',
                            variant: 'warning',
                            solid: true,
                        },
                    })
                    return;
                }
                else
                {
                    this.getList();
                }
            },
            onPageChange(page) {
                this.page = page;
                this.getList();
            },
            onChangePerPage() {
                this.page = 1;
                this.getList();
            },
            getList() {
                let filterData = {
                    'page': this.page,
                    'perPage': this.perPage,
                    'num_ficha': this.num_ficha,
                }
                this.$store.dispatch('codigosTablet/getList', filterData)
                this.$store.commit('codigosTablet/setMensaje', '')
                this.showTable = true
            },
            exportar() {
                if (this.listado.length == 0) {
                    this.showToastMesaje('warning','Debe realizar una búsqueda antes de exportar')
                    return;
                }
                if(this.num_ficha == null)
                {
                    this.showToastMesaje('warning','Al exportar Cursos No Activo debe seleccionar al menos un filtro más')
                    return;
                }else {
                    this.getExportData();
                }
            },
            getExportData() {
                this.$store.dispatch('codigosTablet/exportData', this.num_ficha)
            },
            generarCodigos() {
                this.$store.dispatch('codigosTablet/generateCode', this.num_ficha)
                this.getList()
            },
            showToastMesaje(variant, mensaje) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: mensaje,
                        variant,
                    },
                })
            },
        },
        computed: {
            totalRows() {
                return this.$store.state.codigosTablet.rowsTotal;
            },
            to() {
                return this.$store.state.codigosTablet.to;
            },
            from() {
                return this.$store.state.codigosTablet.from;
            },
            showTables() {
                return this.showTable
            },
            listado() {
                const data = this.$store.state.codigosTablet.list
                this.alumnos = []
                for (const key in data) {
                    const alumno = {
                        rut_alumno: data[key].dt_alumno.alumno.rut_alumno,
                        nombre_alumno: data[key].dt_alumno.alumno.nombre_alumno +' '+ data[key].dt_alumno.alumno.seg_nombre_alumno,
                        apellidos_alumno: data[key].dt_alumno.alumno.apellido_materno_alumno +' '+ data[key].dt_alumno.alumno.apellido_paterno_alumno,
                        num_ficha: data[key].dt_alumno.ficha.num_ficha,
                        fecha_inicio: data[key].dt_alumno.fecha_inicio,
                        fecha_fin: data[key].dt_alumno.fecha_fin,
                        curso: data[key].dt_alumno.curso.nombre_curso,
                        codigo: data[key].codigo,
                    };
                    this.alumnos.push(alumno)
                }
                this.showTable = true
                return this.alumnos
            },
            currentPage() {
                return this.$store.state.codigosTablet.currentPage
            },
            loading() {
                return this.$store.state.codigosTablet.loading;
            },
            codeMissing() {
                return !this.$store.state.codigosTablet.codeMissing;
            },
            mensaje() {
                return this.$store.state.codigosTablet.mensaje;
            },
            showToast() {
                if (this.mensaje != ''){
                    this.showToastMesaje('warning',this.mensaje)
                    this.$store.commit('codigosTablet/setMensaje', '')
                }

            },
        },
    }
</script>

<style scoped>

</style>
